<template>
  <ClientOnly>
    <!-- Reload MFE app when MFE Id is different (using v-key) -->
    <MfePage v-if="props" v-bind="props" :key="id" />
  </ClientOnly>
</template>

<script setup lang="ts">
/**
 * Simple component that acts as a child router for MFEs.
 */
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import MfePage from './MfePage.vue';

const route = useRoute();
const id = computed(() => route.meta?.id as string);

// use computed so that props update on route change
const props = computed(() => route.matched.length > 1 && route.matched[1].props.default());
</script>
