<template>
  <div class="wrapper" :data-hj-suppress="props.hotjarObscureText">
    <UnexpectedErrorPage v-if="hasLoadingError" class="developer-portal">{{
      $t('components.mfe.loadError')
    }}</UnexpectedErrorPage>
    <div v-else :id="appId" :class="['spa-page', { loading: isAppLoading }]" />
    <loader v-if="isAppLoading" />
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import {
  addErrorHandler,
  type AppError,
  getMountedApps,
  registerApplication,
  removeErrorHandler,
  unregisterApplication,
} from 'single-spa';
import { useRuntimeConfig } from 'nuxt/app';
import UnexpectedErrorPage from '@/components/errors/UnexpectedErrorPage.vue';
import Loader from '@/components/loader/Loader.vue';
import cacheBuster from '@/utils/cacheBuster';
import { useUserStore } from '@/store/user';

const runtimeConfig = useRuntimeConfig();
const userStore = useUserStore();

const isAppLoading = ref(true);
const hasLoadingError = ref(false);

const props = defineProps({
  id: { type: String, required: true },
  locale: { type: String, required: true },
  entrypoint: { type: String, required: true },
  routerBase: { type: String, required: true },
  hotjarObscureText: { type: Boolean, required: true },
});

const appId = ref(`single-spa-application:${props.id}`);

const mountApplication = () => {
  const { id, locale, routerBase } = props;
  // add random query param to stop the browser from caching the entrypoint
  const cacheBustedUrl = cacheBuster(runtimeConfig.public[props.entrypoint] as string);
  registerApplication(
    id,
    async () => import(/* @vite-ignore */ cacheBustedUrl),
    () => true, // we always want to show the Single-SPA app no matter the route
    { locale, routerBase, userStore, applyRouteSideEffects: true }
  );
};

const onAppStateChange = ({ detail: { newAppStatuses } }: CustomEvent<{ newAppStatuses: Record<string, string> }>) => {
  if (isAppLoading.value === true && newAppStatuses[props.id] === 'MOUNTED') {
    isAppLoading.value = false;
  }
};

const onError = ({ appOrParcelName }: AppError) => {
  hasLoadingError.value = appOrParcelName === `${props.id}`;
  isAppLoading.value = false;
  window.prerender?.internalServerError();
};

onBeforeMount(async () => {
  // add listeners for state change & errors
  try {
    addErrorHandler(onError);
    window.addEventListener('single-spa:app-change', onAppStateChange as EventListener);

    mountApplication();
    // eslint-disable-next-line no-empty
  } catch (e) {}
});

onBeforeUnmount(async () => {
  // remove listeners
  window.removeEventListener('single-spa:app-change', onAppStateChange as EventListener);
  removeErrorHandler(onError);

  if (getMountedApps().includes(props.id)) {
    await unregisterApplication(props.id);
  }
});
</script>

<style scoped lang="scss">
.wrapper,
.spa-page {
  height: 100%;
}
.spa-page.loading {
  display: none;
}
</style>
